import styled from "styled-components";
import { LAYOUT } from "@/constants/styles";

export const StyledAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-3);
  padding-top: var(--size-3);

  @media (min-width: 768px) {
    gap: var(--size-6);
    padding-top: var(--size-6);
  }
`;

export const StyledSecondarySection = styled.div`
  margin: 0 auto;
  max-width: ${LAYOUT.MAX_LOYALTY_CONTAINER_WIDTH}px;
  padding: ${LAYOUT.GUTTER * 2}px 0;
  width: 100%;
`;

export const StyledLoyaltyContainer = styled.div`
  max-width: ${LAYOUT.MAX_LOYALTY_CONTAINER_WIDTH}px;
  margin: ${LAYOUT.LOYALTY_MENU_HEIGHT_DESKTOP}px auto 0;

  @media (max-width: 767px) {
    margin-top: ${LAYOUT.LOYALTY_MENU_HEIGHT_MOBILE}px;
  }
`;

export const StyledFlexedHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const StyledTopLevelLoyaltyContainer = styled.div`
  // to ensure first component never goes behind the loyalty nav
  margin-top: ${LAYOUT.LOYALTY_MENU_HEIGHT_DESKTOP}px;

  @media (max-width: 767px) {
    margin-top: ${LAYOUT.LOYALTY_MENU_HEIGHT_MOBILE}px;
  }
`;

export const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
`;

export const StyledInlineFormContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

interface StyledFormFieldProps {
  autoHeight?: boolean;
}

export const StyledFormField = styled.div<StyledFormFieldProps>`
  flex: 1 1 33.333%;
  height: ${({ autoHeight, theme }) =>
    autoHeight ? "auto" : `${theme.forms.field_height + LAYOUT.GUTTER}px`};
  overflow: hidden;
  position: relative;

  &:not(:first-child) {
    padding-left: ${LAYOUT.GUTTER}px;

    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    flex-basis: 100%;
  }
`;

export const StyledCancelAndSubmitButtons = styled.div`
  display: flex;
  justify-content: right;
  align-self: flex-end;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin: 0;

    button {
      width: 100%;
    }

    > div:first-child {
      margin: ${LAYOUT.GUTTER}px 0 0 0;
    }
  }
`;

export const StyledInlineCancelAndSubmitButtons = styled(
  StyledCancelAndSubmitButtons
)`
  position: relative;
  top: -20px;

  @media (max-width: 767px) {
    top: 0;
  }
`;

export const StyledContactInfoContainer = styled.div`
  padding: ${LAYOUT.GUTTER}px;

  p {
    margin: ${LAYOUT.GUTTER / 4}px 0;
    font-size: 16px;
  }
`;

export const StyledContactInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const StyledCancelEdit = styled.div`
  display: flex;
  margin-right: ${LAYOUT.GUTTER}px;
  align-items: center;
`;

interface StyledLinkButtonProps {
  bold?: boolean;
}

export const StyledLinkButton = styled.button<StyledLinkButtonProps>`
  font-family: ${({ bold, theme }) =>
    bold
      ? theme.primary_font.bold.font_family
      : theme.primary_font.regular.font_family};
  font-size: 16px;
  color: ${({ theme }) => theme.global.primary_active_color};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
`;
