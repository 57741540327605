import styled, { type StyledProps } from "styled-components";
import { LAYOUT, ORDER } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface StyledLocationCardProps {
  isActive?: boolean;
  editFavoriteLocation?: boolean;
  favorites?: boolean;
}

export const StyledLocationCard = styled.div<StyledLocationCardProps>`
  background: ${({ theme }) => theme.location_card.background_color};
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive
        ? theme.global.primary_active_color
        : theme.location_card.border_color};
  border-radius: ${({ theme }) => theme.location_card.border_radius}px;
  margin: 0 0 ${LAYOUT.GUTTER}px 0;
  padding: ${LAYOUT.GUTTER}px;

  // Only on Account page
  ${({ editFavoriteLocation }) =>
    editFavoriteLocation &&
    `
    position: relative;
  `}

  // Store Location Card in Account 'Favorites' section
  ${({ favorites }) =>
    favorites &&
    `
    width: calc(50% - ${LAYOUT.GUTTER / 2}px);
  `}

  // TODO - fix
  @media (max-width: 767px) {
    ${({ favorites }: StyledProps<StyledLocationCardProps>) =>
      favorites
        ? `
      width: 100%;
    `
        : `
      z-index: ${ORDER.HEADER};
    `}
  }
`;

export const StyledLocationCardFlex = styled.div`
  align-items: start;
  display: flex;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const StyledCardInfo = styled.div(({ theme }) => ({
  flex: 2,

  div: {
    textAlign: "left",
    "@media (max-width: 767px)": {
      textAlign: "center",
    },
  },

  h3: {
    display: "inline-block",
    margin: 0,
    position: "relative",
    ...getFontStyles(theme.location_card.secondary_font, [
      "color",
      "font_family",
      "font_size",
      "font_style",
      "line_height",
    ]),

    "> button": {
      position: "absolute",
      top: 0,
      right: "-5px",
    },
  },

  p: {
    margin: "8px 0 0",
    ...getFontStyles(theme.location_card.font),

    "@media (max-width: 767px)": {
      textAlign: "center",
    },

    b: {
      fontWeight: "bold",
    },

    a: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  "@media (max-width: 767px)": {
    textAlign: "center",
  },
}));

export const StyledCardLinks = styled.div.attrs({
  "data-css-override": "StyledCardLinks",
})`
  flex: 1;
  padding-left: ${LAYOUT.GUTTER}px;
  text-align: center;

  @media (max-width: 767px) {
    padding-left: 0;
    display: block;

    a:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledDistance = styled.p`
  display: none;
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};

  @media (max-width: 767px) {
    display: block;
  }
`;
