import { darken } from "polished";
import styled from "styled-components";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import GenericModal from "@/components/uielements/genericModal";
import { MODAL } from "@/constants/events";
import { LAYOUT } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safePolished } from "@/utils/safePolished";

interface Props {
  ctas: any;
  visible: boolean;
  onToggle: () => void;
}

const SecondaryCtaModal = ({ ctas, onToggle, visible }: Props) => (
  <GenericModal
    modalOpen={visible}
    toggleModal={() => onToggle()}
    name={MODAL.CTA_OVERFLOW}
  >
    <StyledSecondaryCtaModal>
      <StringAccessor
        tag="h1"
        html={true}
        accessor="location_ctas.overflow_modal_headline"
      />
      <StringAccessor
        tag="p"
        html={true}
        accessor="location_ctas.overflow_modal_subheadline"
      />
      <StyledCtaList>{ctas}</StyledCtaList>
    </StyledSecondaryCtaModal>
  </GenericModal>
);

const StyledSecondaryCtaModal = styled.div`
  padding: ${LAYOUT.GUTTER * 2}px;
`;

const StyledCtaList = styled.div(({ theme }) => ({
  "> a": {
    background: theme.buttons.knockout_button_background_color,
    borderRadius: `${theme.buttons.knockout_button_border_radius}px`,
    border: `1px solid ${theme.buttons.knockout_button_border_color}`,
    margin: "40px 0 0",
    padding: "15px",
    textAlign: "center",
    textDecoration: "none",
    ...getFontStyles(
      theme.buttons.knockout_font,
      ["color", "font_family", "font_weight", "font_size", "line_height"],
      {
        /** @FIXME font refactor. how will this work if primary_font.color is returned as inherit | initial | etc */
        color: (value) => value ?? theme.buttons.knockout_button_border_color,
      }
    ),

    "&:hover": {
      background: "transparent",
      borderColor: `${
        theme.buttons.knockout_button_border_color
          ? safePolished(darken)(
              0.025,
              theme.buttons.knockout_button_border_color
            )
          : "transparent"
      }`,
    },
  },
}));

export default SecondaryCtaModal;
