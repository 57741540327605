import { type Location } from "@koala/sdk";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { FavoriteLocationDropdownField } from "@/components/uielements/favoriteLocationsDropdownField/favorite-locations-dropdown-field";
import {
  StyledExplanationText,
  StyledForm,
} from "@/components/uielements/forms";
import { Modal } from "@/components/uielements/modal";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import meActions from "@/redux/me/actions";
import { meSliceSelector } from "@/redux/me/reducer";

interface Props {
  onToggle: () => void;
}

const disableFavLocationSaveButton = (
  inputValue: string,
  locations: Location[]
) => {
  if (!inputValue) {
    return true;
  }

  // Disable if we've selected a fave location that already exists
  return locations.some((location) => location.id === +inputValue);
};

export const FavoriteLocationForm = ({ onToggle }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const { favoriteLocations } = useSelector(meSliceSelector);
  const dispatch = useDispatch();

  const submit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(meActions.addFavoriteLocation(Number(inputValue)));
    onToggle();
  };

  return (
    <>
      <Modal.Title>
        <StringAccessor
          accessor="account.favorite_location_modal_header"
          html={true}
        />
      </Modal.Title>
      {/* @ts-expect-error mismatched callback type. Form submitted twice? */}
      <StyledForm onSubmit={submit}>
        <Modal.Content>
          <StyledExplanationText>
            <StringAccessor
              accessor="account.favorite_location_modal_subheader"
              html={true}
            />
          </StyledExplanationText>

          <FavoriteLocationDropdownField
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputValue(e.target.value)
            }
            width="100%"
          />
        </Modal.Content>

        <Modal.Footer>
          <Modal.Cancel>Cancel</Modal.Cancel>

          <StyledPrimaryButton
            type="submit"
            buttonTheme="secondary"
            disabled={disableFavLocationSaveButton(
              inputValue,
              /** @TODO ensure that `favoriteLocations` isn't null. */
              // @ts-ignore
              favoriteLocations
            )}
          >
            Save
          </StyledPrimaryButton>
        </Modal.Footer>
      </StyledForm>
    </>
  );
};
