import { type Location, CONVEYANCE_TYPES } from "@koala/sdk";
import Link from "next/link";
import { StyledButton } from "./styles";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents } from "@/analytics/events/constants";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { CTA } from "@/constants/cta";
import { API_CONVEYANCE_TYPES } from "@/constants/events";
import { useDispatch, useSelector } from "@/redux";
import conveyanceModeActions from "@/redux/conveyanceMode/actions";
import globalActions from "@/redux/global/actions";
import {
  interpolateLocationCtaValue,
  reconcileCustomAttributeWithGlobalValue,
  safelyGetString,
} from "@/utils/stringHelpers";

interface Props {
  accessor: string;
  location: Location;
  position: number;
  favorites?: boolean;
}

export const LocationCta = ({
  accessor,
  location,
  position,
  favorites,
}: Props) => {
  const { webConfig, strings } = useSelector((state) => state.app.cmsConfig);
  const { fulfillment } = useSelector((state) => state.app.basket);
  const dispatch = useDispatch();

  const deriveFinalCtaValue = (value: string) => {
    let { finalValue } = interpolateLocationCtaValue(
      value,
      location,
      webConfig
    );

    // If we have an existing delivery address AND the CTA is a delivery trigger, the CTA will be treated like a normal location link
    // however, since the value is still $deliveryCta$ we need to replace this with the path to the store menu page
    if (CTA.DELIVERY_URL && fulfillment?.address) {
      finalValue = interpolateLocationCtaValue(
        CTA.PICKUP_URL,
        location,
        webConfig
      ).finalValue;
    }

    return finalValue;
  };

  const globalValue = safelyGetString(
    strings,
    `location_ctas.${accessor}_value`,
    location
  );
  const globalLabel = safelyGetString(
    strings,
    `location_ctas.${accessor}_label`,
    location
  );
  const { label, value } = reconcileCustomAttributeWithGlobalValue(
    // @ts-expect-error improve typing of custom attributes.
    location.attributes,
    position,
    globalValue,
    globalLabel
  );

  // Ensure we have both values before displaying cta
  if (!value || !label) {
    return null;
  }

  // If the location is not online and the CTA is directing to a Koala url, hide the CTA
  if (
    !location.provider_enabled &&
    (value === CTA.DELIVERY_URL || value === CTA.PICKUP_URL)
  ) {
    return null;
  }

  // We should only render the Koala-specific delivery trigger if the location supports delivery. Otherwise, we should render nothing
  const hideDeliveryTrigger = !location.supports_delivery;

  // Derive button theme
  // TODO: improve this type and remove string type to narrow it to proper union type
  /** @TODO fix accessor reconciliation. */
  // @ts-expect-error: cannot type due to lookup method
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const buttonTheme = `${webConfig.location_card[`${accessor}_button_theme`]}`;

  // Determine whether we're accessing location card from homepage or Favorites
  const analyticsLabel = favorites
    ? GlobalEvents.LOCATIONS__FAVORITE_LOCATION_CTA
    : GlobalEvents.LOCATIONS__PRIMARY_LOCATION_CTA;

  /**
   * Primary and Secondary Location CTAs
   */
  return (
    // If we have a deliveryUrl trigger BUT an address already exists in the basket,
    // do not trigger address validation - the cart reconciliation logic will handle
    // any different location revalidation that is needed on the menu page
    value === CTA.DELIVERY_URL && !fulfillment?.address ? (
      // If delivery button clicked AND there's no existing delivery address OR no items in the cart, trigger delivery modal
      <StyledButton
        size="small"
        shouldHide={hideDeliveryTrigger}
        buttonTheme={buttonTheme}
        className={CSS_CLASSES.LOCATION_CARD.PICKUP_BUTTON}
        aria-label={`${label} from ${location.label}`}
        onClick={() => {
          dispatch(
            globalActions.toggleFulfillmentModal(
              true,
              location,
              CONVEYANCE_TYPES.DELIVERY
            )
          );

          // Events
          genericEventHandler(GlobalEvents.LOCATIONS__TRIGGER_DELIVERY_MODAL, {
            name: location.label,
            details: CONVEYANCE_TYPES.DELIVERY,
          });
        }}
      >
        {label}
      </StyledButton>
    ) : (
      // else render normal link to designated page
      <Link href={deriveFinalCtaValue(value)} passHref={true}>
        <StyledButton
          className={CSS_CLASSES.LOCATION_CARD.PICKUP_BUTTON}
          as="a"
          size="small"
          shouldHide={value === CTA.DELIVERY_URL && hideDeliveryTrigger}
          buttonTheme={buttonTheme}
          data-testid="link-button-pickup-order"
          aria-label={`${label} from ${location.label}`}
          onClick={() => {
            // Make sure Pickup CTA is always setting orders to Pickup
            if (value === CTA.PICKUP_URL) {
              dispatch(conveyanceModeActions.clearDeliveryAddress(location.id));
              dispatch(
                conveyanceModeActions.conveyanceModeSet({
                  type: API_CONVEYANCE_TYPES.PICKUP,
                  details: [],
                })
              );
            }

            // Internal Delivery or Pickup button
            value === CTA.DELIVERY_URL || value === CTA.PICKUP_URL
              ? genericEventHandler(analyticsLabel, {
                  name: location.label,
                  details:
                    value === CTA.DELIVERY_URL
                      ? CONVEYANCE_TYPES.DELIVERY
                      : CONVEYANCE_TYPES.PICKUP,
                })
              : // External URL
                genericEventHandler(GlobalEvents.GENERIC__CTA, {
                  name: location.label,
                  details: value,
                });
          }}
        >
          {label}
        </StyledButton>
      </Link>
    )
  );
};
