import styled from "styled-components";
import { LAYOUT } from "@/constants/styles";

interface StyledSecondaryButtonProps {
  shouldHide?: boolean;
}

export const StyledSecondaryButton = styled.a<StyledSecondaryButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  display: ${({ shouldHide }) => (shouldHide ? "none" : "block")};
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  margin: 15px ${LAYOUT.GUTTER}px 0 0;
  padding: 0;
  text-align: left;
  text-decoration: underline;
`;
