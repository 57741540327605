import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FeatureAccessor } from "@/components/featureAccessor";
import { StyledFormField } from "@/components/uielements/form-fields/styles";
import { ReduxFormSelect } from "@/components/uielements/selectCheckout";
import { FEATURE_FLAGS } from "@/constants/features";
import { useSelector } from "@/redux";
import { locationsActions } from "@/redux/locations/actions";
import { getLocationDataForDropdown } from "@/utils/locations";
import { MagicBoxParams } from "@/utils/magicBox";
import { safelyGetString } from "@/utils/stringHelpers";

interface Props {
  input?: {
    name?: string;
    placeholder?: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  meta?: any;
  width?: string;
  errorMessage?: string;
}

export function FavoriteLocationDropdownField({
  handleChange,
  input,
  meta,
  width,
  errorMessage,
}: Props) {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.app.locations.list);
  const favoriteLocationId = useSelector(
    (state) => state.app.me.favoriteLocationId
  );
  const strings = useSelector((state) => state.app.cmsConfig.strings);
  const webConfig = useSelector((state) => state.app.cmsConfig.webConfig);
  const disabled = webConfig.accounts.favorite_location === "disabled";

  useEffect(() => {
    if (!disabled) {
      // Assign magicbox parameters
      const magicBox = new MagicBoxParams()
        .setPagination(1, 50)
        .setSorts({ state_id: "asc", label: "asc" });

      // Fetch all locations
      dispatch(locationsActions.fetchAllLocations(magicBox));
    }
  }, []);

  return (
    <FeatureAccessor
      featureFlag={FEATURE_FLAGS.ACCOUNT__FAVORITE_LOCATIONS_ENABLED}
    >
      <StyledFormField width={width}>
        <ReduxFormSelect
          errorMessage={errorMessage}
          /** @TODO ensure that `name` is defined. */
          // @ts-expect-error
          name={input?.name}
          handleChange={handleChange}
          optionsArray={
            locations?.length ? getLocationDataForDropdown(locations) : []
          }
          title={
            locations?.length
              ? safelyGetString(strings, "account.add_favorite_location_header")
              : "Loading Locations..."
          }
          loadingMessage="Loading Locations..."
          /** @TODO differentiate between `null` and `undefined`. */
          // @ts-expect-error
          defaultValue={
            favoriteLocationId ? favoriteLocationId.toString() : null
          }
          meta={meta}
          titleEnabled={true}
        />
      </StyledFormField>
    </FeatureAccessor>
  );
}
