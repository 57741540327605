import { type Location } from "@koala/sdk";
import Link from "next/link";
import { StyledButton } from "./styles";
import { K_ANALYTICS_EVENTS } from "@/constants/events";
import { useSelector } from "@/redux";
import { fireGaEvent, gaActions, gaCats } from "@/utils/googleAnalytics";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";
import { getLocationId } from "@/utils/locations";

interface Props {
  location: Location;
  label: string;
  favorites?: boolean;
}

function MenuCta({ location, label, favorites }: Props) {
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  // Determine whether we're accessing location card from homepage or Favorites
  const koalaAnalyticsLabel = favorites
    ? K_ANALYTICS_EVENTS.LOCATION_FAVORITE_CTA
    : K_ANALYTICS_EVENTS.LOCATION_CTA;

  return (
    <Link
      href={`/store/${getLocationId(location, webConfig)}/${location.label}`}
      passHref={true}
    >
      <StyledButton
        as="a"
        buttonTheme="primary"
        onClick={() => {
          fireGaEvent(gaCats.order, gaActions.viewMenu, {
            label: location.label,
          });
          fireKAnalyticsEvent(koalaAnalyticsLabel, { name: label });
        }}
      >
        {label}
      </StyledButton>
    </Link>
  );
}

export default MenuCta;
