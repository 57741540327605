import { type Location } from "@koala/sdk";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FavoriteLocationForm } from "@/components/account/accountInformation/favoriteLocation/form";
import Icon from "@/components/uielements/icon";
import { Modal } from "@/components/uielements/modal";
import { useWebConfig } from "@/redux/cmsConfig";
import meActions from "@/redux/me/actions";

interface Props {
  location: Location;
}

export const FavoriteLocationActions = ({ location }: Props) => {
  // in order to correctly use a menu and modal from radix, we have to split the
  // rendering and add some modal wiring
  const [showModal, setShowModal] = useState(false);
  const webConfig = useWebConfig();
  const required = webConfig.accounts.favorite_location === "required";
  const dispatch = useDispatch();

  return (
    <>
      <DropdownMenu.Root>
        <Edit>
          <Icon.Ellipsis title="Edit favorite location" />
        </Edit>

        <Content>
          <Item onSelect={() => setShowModal(true)}>
            Edit Favorite Location
          </Item>

          {!required && (
            <Item
              onSelect={() =>
                dispatch(
                  meActions.removeFavoriteLocation(location.id, location.label)
                )
              }
            >
              Delete Favorite Location
            </Item>
          )}
        </Content>
      </DropdownMenu.Root>

      <Modal.Container
        onCancel={() => setShowModal(false)}
        requestVisibility={showModal}
      >
        {(toggle) => (
          <FavoriteLocationForm
            onToggle={() => {
              toggle();
              setShowModal(false);
            }}
          />
        )}
      </Modal.Container>
    </>
  );
};

const Content = styled(DropdownMenu.Content)`
  ${({ theme }) => `
    background: ${theme.location_card.background_color};
    border: 1px solid ${theme.location_card.border_color};
    border-radius: ${theme.location_card.border_radius}px;
  `}

  display: flex;
  flex-direction: column;
`;

const Edit = styled(DropdownMenu.Trigger)`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
`;

const Item = styled(DropdownMenu.Item)`
  background: none;
  border: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  padding: var(--size-3);

  text-decoration: none;

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.location_card.border_color};
  }
`;
