import { type Location } from "@koala/sdk";
import { CrossStreet } from "../crossStreet";
import MenuCtaManager from "../ctas/menuCta";
import { LocationCta } from "../ctas/primaryCta";
import SecondaryCtaManager from "../ctas/secondaryCta";
import { LocationConveyance } from "./components";
import {
  StyledCardInfo,
  StyledCardLinks,
  StyledDistance,
  StyledLocationCard,
  StyledLocationCardFlex,
} from "./styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { FavoriteLocationActions } from "@/components/locations/favorite-location-actions";
import { Stack } from "@/components/uielements/stack/stack";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { LOCATION_ATTRIBUTE_POSITIONS } from "@/constants/locations";
import { deriveTodaysHours, googleMapsUrl } from "@/utils/locations";

interface Props {
  activeLocationId?: number;
  location: Location;
  setActiveLocation?: (id: number) => void;
  menuCta?: string;
  favorites?: boolean;
  editFavoriteLocation?: boolean;
}

export const LocationCard = ({
  activeLocationId,
  menuCta,
  location,
  setActiveLocation,
  favorites,
  editFavoriteLocation,
}: Props) => {
  const deriveLocationInfo = (location: Location) => {
    const { cached_data } = location;

    return (
      <StyledCardInfo>
        <div role="region" aria-label="Location name">
          <h3>{cached_data?.label}</h3>
        </div>
        <p>
          <a
            data-testid="locations-card-address-container"
            href={googleMapsUrl(cached_data)}
            target={cached_data ? "_blank" : "_self"}
            rel="noreferrer"
          >
            {location.street_address}&nbsp;
            <br />
            <CrossStreet crossStreetDetail={location.cross_street} />
            {location.city}, {cached_data?.state} {location.zip_code}
          </a>
          <br />
          <span data-testid="location-phone">{cached_data?.phone_number}</span>
          <br />

          <span>
            {deriveTodaysHours(
              location,
              cached_data ? cached_data.utc_offset : 0
            )}
          </span>
        </p>

        <LocationConveyance location={location} />

        {/* Show distance on mobile list view, since there's no map */}
        {location.distance_miles && location.distance_miles > 0 && (
          <StyledDistance>
            {Math.round(location.distance_miles * 100) / 100} miles away
          </StyledDistance>
        )}
      </StyledCardInfo>
    );
  };

  if (!location) {
    return null;
  }

  const isUnavailableLocationMessageShown =
    !location.provider_enabled &&
    (!location.attributes?.length ||
      !location.attributes?.some((attr) => attr.value && attr.label));

  return (
    <StyledLocationCard
      // location card is not clickable on mobile map display
      className={`${CSS_CLASSES.LOCATION_CARD.CONTAINER} ${
        activeLocationId === location.id && "active"
      }`}
      /** @TODO it's not accessible to have an `onClick` on a div. Fix this. */
      // @ts-expect-error
      onClick={setActiveLocation ? () => setActiveLocation(location.id) : null}
      isActive={activeLocationId === location.id}
      id={`Location-${location.id}`}
      favorites={favorites}
      editFavoriteLocation={editFavoriteLocation}
      tabIndex={0}
      data-testid="location-card-container"
    >
      <StyledLocationCardFlex>
        {deriveLocationInfo(location)}
        <StyledCardLinks>
          {menuCta && (
            <MenuCtaManager
              label={menuCta}
              location={location}
              favorites={favorites}
            />
          )}

          {/* Show ordering links if menu CTA override doesn't exist */}
          {!menuCta && (
            <>
              {/* Location is active and accepting orders */}
              <LocationCta
                accessor="primary"
                location={location}
                position={LOCATION_ATTRIBUTE_POSITIONS.PRIMARY_ORDER_CTA}
                favorites={favorites}
              />

              <LocationCta
                accessor="secondary"
                location={location}
                position={LOCATION_ATTRIBUTE_POSITIONS.SECONDARY_ORDER_CTA}
                favorites={favorites}
              />
            </>
          )}

          {/* // Location is not currently accepting online orders and no CTAs are displayed*/}
          {isUnavailableLocationMessageShown && (
            <p>
              <i>
                <StringAccessor
                  accessor="locations.location_inactive"
                  html={true}
                />
              </i>
            </p>
          )}
        </StyledCardLinks>
      </StyledLocationCardFlex>

      {/* Show footer links if CTA override doesn't exist */}
      {(!menuCta || editFavoriteLocation) && (
        <Stack position="trailing">
          <SecondaryCtaManager location={location} favorites={favorites} />
          {/* For editing Favorite Location */}
          {editFavoriteLocation && (
            <FavoriteLocationActions location={location} />
          )}
        </Stack>
      )}
    </StyledLocationCard>
  );
};
